import {convertEnToPe} from "../../utiles/Ops";
import {IconSetting} from "../Icons/Setting";
import {useNavigate} from "react-router-dom";

export const CouponMenu = ({user}) => {
    const navigate = useNavigate();
    return (
        <div className="flex justify-between items-start w-full bg-[#eb658a] rounded p-3 mb-2 cursor-pointer"
             onClick={() => navigate("/introductions")}>
            <div className="flex gap-x-2">
                <IconSetting color="white"/>
                <div>رویدادهای ماندگار</div>
            </div>
            <div className="flex gap-x-2">
                <span>{convertEnToPe(user?.introductions?.length)}</span>
            </div>
        </div>
    );
};
