import {fetchData} from "./fetch";
import {convertPeToEn} from "../utiles/Ops";
import PN from "persian-number";

// const serverAddress = "http://93.115.146.206/api/v1/";
const serverAddress = "https://api.ojikala.com/api/v1/";
const sendCode = async (username, loop = 1) => {
    console.log(convertPeToEn(username))
    const variables = {
        url: `${serverAddress}UserManagement/RegisterMobileAndSendSMS`,
        variables: {userName: PN.convertPeToEn(username)}
    };
    const result = await fetchData(variables, "POST");

    if (result?.isSuccess) {
        return {
            data: result,
            status: result?.isSuccess,
        };
    } else {
        if (username && loop <= 3)
            await sendCode(username, ++loop);
        return {
            errors: result?.message || false,
            status: result?.isSuccess || false,
        };
    }
};
const authorize = async (username, password) => {
    const variables = {
        url: `${serverAddress}UserManagement/Token`,
        variables: new URLSearchParams({
            'username': PN.convertPeToEn(username),
            'password': PN.convertPeToEn(password),
            'grant_type': 'password'
        })
    };
    const result = await fetchData(variables, "POST", false);

    if (result?.access_token) {
        return {
            data: result?.access_token,
            status: true,
        };
    } else {
        return {
            errors: result?.Message || false,
            status: result?.StatusCode || false,
        };
    }
};
const getUserData = async () => {
    const variables = {
        url: `${serverAddress}Invoice`,
    };
    const result = await fetchData(variables, "GET");

    if (result?.isSuccess) {
        return {
            data: result?.data,
            status: result?.isSuccess,
        };
    } else {
        return {
            errors: result?.Message || false,
            status: result?.StatusCode || false,
        };
    }
};
const saveProfile = async (data) => {
    const variables = {
        url: `${serverAddress}Invoice`,
        variables: data
    };
    const result = await fetchData(variables, "POST");
    if (result?.isSuccess) {
        return {
            data: result?.data,
            status: result?.isSuccess,
        };
    } else {
        return {
            errors: result?.Message,
            status: result?.isSuccess || false,
        };
    }
};

export {sendCode, authorize, getUserData, saveProfile};
