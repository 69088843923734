import {convertToPrice} from "../utiles/Ops";
import {useNavigate} from "react-router-dom";

export const CustomerLevel = ({user}) => {
    const navigate = useNavigate();
  return (
    <div className="w-full h-[272px] gap-y-1">
        <div className="bg-[#4E94C8] rounded h-[calc(50%-8px)] mb-2 justify-start flex flex-col items-center py-3">
            <div>{user?.rateName}</div>
            <hr className="bg-black w-[60%] mt-1 mb-1"/>
            <div className="text-[12px] py-1">امتیاز فعلی : {user?.rate}</div>
            <div className="text-[12px] py-1">{user?.nextRate} امتیاز مانده تا سطح بعدی</div>
            <div className="text-[12px] py-1 text-[#172B74] cursor-pointer" onClick={()=>navigate('/help')}>راهنمای امتیازات</div>
        </div>
        <div className="bg-[#3A73A8] rounded h-1/2 justify-center flex flex-col items-center">
        <div className="text-[13px]">سابقه حضور شما در بازرگانی ماندگار</div>
        <hr className="bg-black w-[60%] mt-3 mb-2"/>
        <div className="text-[16px]">{user?.party?.lifeTimeDays || 0} روز</div>
      </div>
    </div>
  );
};
