import {createContext, useContext, useEffect, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useLocalStorage} from "./useLocalStorage";

const AuthContext = createContext({});

export const AuthProvider = ({children}) => {
    const [token, setToken] = useLocalStorage("token");
    const [user, setUser] = useLocalStorage("user");
    const [isLogin, setIsLogin] = useState(!!(user));
    const navigate = useNavigate();

    useEffect(() => {
        if (!token || !user) {
            setUser(null);
            setToken(null);
            setIsLogin(false);
        } else
            setIsLogin(true);
    }, [user]);

    const login = async (input) => {
        setToken(input);
        setIsLogin(true);
        navigate("/");
    };

    const logout = () => {
        setToken(null);
        setUser(null);
        setIsLogin(false);
        navigate("/", {replace: true});
    };

    const value = useMemo(
        () => ({
            user,
            setUser,
            token,
            login,
            logout,
            isLogin
        }),
        // eslint-disable-next-line
        [token, user],
    );
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};
