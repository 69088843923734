import {IconHome} from "../Icons/Home";
import {IconInstagram} from "../Icons/Social";
import {IconHeart} from "../Icons/Heart";
import {IconUserPlus} from "../Icons/User";
import {IconShop} from "../Icons/Shop";
import LinkNav from "./NavLink";

export default function MobileMenuButton() {
    return (
        <nav className="bg-[#081325] sticky bottom-0 w-full px-5">
            <ul className="flex justify-between items-center h-[72px]">
                <li>
                    <LinkNav to="https://instagram.com/ojikala_com" target="_blank" className="py-5 px-5">
                        <IconInstagram/>
                    </LinkNav>
                </li>
                <li>
                    <LinkNav to="https://ojikala.com/" target="_blank" className="py-3 px-5">
                        <IconShop/>
                    </LinkNav>
                </li>
                <li>
                    <LinkNav to="/" className="py-3 px-5">
                        <IconHome/>
                    </LinkNav>
                </li>
                {/*<li>*/}
                {/*  <LinkNav to="/fav" className="py-3 px-5">*/}
                {/*    <IconHeart />*/}
                {/*  </LinkNav>*/}
                {/*</li>*/}
                <li>
                    <LinkNav to="/profile" className="py-3 px-5">
                        <IconUserPlus/>
                    </LinkNav>
                </li>
            </ul>
        </nav>
    );
}
