import { convertToPrice } from "../../utiles/Ops";
import {IconEmail} from "../Icons/Social";

export const GiftMenu = ({user}) => {
  return (
    <div className="flex justify-between items-start w-full bg-[#9e8cec] rounded p-3 mb-2">
      <div className="flex gap-x-2">
        <IconEmail color="white" />
        <div>مبلغ کیف پول</div>
      </div>
      <div className="flex gap-x-2">
        <span>{convertToPrice(user?.cashBack)}</span>
        <span>ریال</span>
      </div>
    </div>
  );
};
