import PN from "persian-number";
import isNumber from "lodash.isnumber";

var shamsi = require("shamsi-date-converter");

export const convertEnToPe = (value) => {
    return PN.convertEnToPe(value);
    // const number = Number(value);
    // if (!isNaN(number) && isNumber(number)) {
    //   return PN.convertEnToPe(number);
    // } else {
    //   return value;
    // }
};

export const generateFullName = (user) => {
    try {
        let fullname;
        if (user?.party?.name)
            fullname = user?.party?.name;
        if (user?.party?.lastName)
            fullname += " " + user?.party?.lastName;
        if (!fullname) fullname = "وارد نشده!";
        return fullname;
    } catch (error) {
        return "";
    }
};
export const convertPeToEn = (value) => {
    const number = Number(value);
    if (!isNaN(number) && isNumber(number)) {
        return PN.convertPeToEn(number);
    } else {
        return value;
    }
};

export const convertToPrice = (value) => {
    const number = Number(value);
    if (!isNaN(number) && isNumber(number)) {
        return PN.convertEnToPe(PN.sliceNumber(number));
    } else {
        return value;
    }
};

export const validateInputNumber = (value, max) => {
    if (value.length > max) return false;
    if (/^\d+$/.test(value) || value === "") return true;
};

export const ToShamsiEnToPe = (date) => {
    return convertEnToPe(shamsi.gregorianToJalali(date).join("/"));
};
