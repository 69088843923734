import Helmet from "react-helmet";
import {convertEnToPe, convertToPrice, ToShamsiEnToPe} from "../utiles/Ops";
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from "react";
import {Spinner} from "../compoenents/common/Spinner";

export default function HelpPage() {
    const {user} = useAuth();
    const [loading, setLoading] = useState(false);

    return (
        <div>
            <Helmet>
                <title>راهنمای امتیازات</title>
            </Helmet>
                <div className="rounded bg-[#221f3f] p-2">
                    <img src="https://ojikala.com/wp-content/uploads/2024/10/club-mandegar-new.jpg"/>
                </div>
        </div>
    );
}
