import {NavLink} from "react-router-dom";

export default function LinkNav({to, className, children, target="_self"}) {
    return (
        <NavLink
            target={target}
            to={to}
            className={({isActive}) => {
                return `${className} block ${isActive ? "bg-[#394152] rounded-xl" : ""}`;
            }}
        >
            {children}
        </NavLink>
    );
}
