import {convertEnToPe} from "../../utiles/Ops";
import {IconInvoice} from "../Icons/Invoice";
import {useNavigate} from "react-router-dom";

export const InvoiceMenu = ({user}) => {
    const navigate = useNavigate();
    return (
        <div className="flex justify-between items-start w-full bg-[#69b7ff] rounded p-3 mb-2 cursor-pointer"
             onClick={() => navigate("/invoices")}>
            <div className="flex gap-x-2">
                <IconInvoice color="white"/>
                <div>فاکتور خرید</div>
            </div>
            <div className="flex gap-x-2">
                <span>{convertEnToPe(user?.vwInvoices?.length)}</span>
            </div>
        </div>
    );
};
