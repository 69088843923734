import Helmet from "react-helmet";
import {useAuth} from "../hooks/useAuth";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getUserData} from "../services/login";
import {convertEnToPe, convertToPrice} from "../utiles/Ops";

export default function Invoice() {
    const {user} = useAuth();
    const params = useParams();
    const invoice_id = params.invoice_id;
    const invoice = user?.vwInvoices.find(item => item.invoiceId == invoice_id);

    return (
        <>
            <Helmet>
                <title>نمایش تمام فاکتور ها</title>
            </Helmet>
            <div className="h-full bg-white p-1 text-black">
                <div
                    className="w-full bg-[#172B74] p-1 flex justify-center items-center border-2 border-b-0 border-black text-[14px] text-white">
                    مشخصات فاکتور
                </div>
                {/*<div className="h-[77px] w-full flex border-2 border-b-0 border-black">*/}
                {/*    <div className="w-[30px] text-black relative bg-gray-300 border-l-2 border-black">*/}
                {/*        <div className="rotate-90 absolute -right-[14px] top-[26px]">*/}
                {/*            فروشنده*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="w-[100%]"></div>*/}
                {/*</div>*/}
                {/*<div className="h-[64px] w-full flex border-2 border-b-0 border-black">*/}
                {/*    <div className="w-[30px] text-black relative bg-gray-300 border-l-2 border-black">*/}
                {/*        <div className="rotate-90 absolute -right-[7px] top-[20px]">*/}
                {/*            خریدار*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*    <div className="w-[100%]"></div>*/}
                {/*</div>*/}
                <div
                    className="w-full bg-[#172B74] p-1 flex justify-center items-center border-2 border-b-0 border-black text-[14px] text-white">
                    شرح خرید
                </div>
                <div
                    className="h-[32px] w-full flex border-2 border-b-0 border-black text-black justify-start items-center">
                    <div className="w-[25%] border-l-2 border-black text-center">
                        نام کالا
                    </div>
                    <div className="w-[25%] border-l-2 border-black text-center">
                        تعداد
                    </div>
                    <div className="w-[25%] border-l-2 border-black text-center">
                        قیمت
                    </div>
                    <div className="w-[25%] text-center">قیمت نهایی</div>
                </div>
                {invoice?.vwInvoiceItems?.map((item, index) => (
                    <div key={index} className="w-full flex border-2 border-b-0 border-black">
                        <div
                            className="w-[25%] border-l-2 border-black text-center flex items-center justify-center p-2">
                            {item.itemTitle}
                        </div>
                        <div className="w-[25%] border-l-2 border-black text-center flex items-center justify-center">
                            {convertEnToPe(item.quantity)}
                        </div>
                        <div className="w-[25%] border-l-2 border-black text-center flex items-center justify-center">
                            {convertToPrice(item.fee)}
                        </div>
                        <div className="w-[25%] border-black text-center flex items-center justify-center">
                            {convertToPrice(item.netPrice)}
                        </div>
                    </div>
                ))}
                {/*footer*/}
                <div
                    className="h-[32px] w-full flex border-2  border-black justify-between items-center px-2 text-[14px]">
                    <div>مبلغ فاکتور</div>
                    <div className="flex">
                        <div>{convertToPrice(invoice?.netPrice)}</div>
                        <div className="mr-1">ریال</div>
                    </div>
                </div>
                {/*footer*/}
            </div>
        </>
    );
}
