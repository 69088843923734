import {useEffect, useRef, useState} from "react";
import {useAuth} from "../hooks/useAuth";
import {IconUserBold} from "../compoenents/Icons/User";
import {authorize, sendCode} from "../services/login";
import {LogoBase} from "../compoenents/common/Logo";
import Helmet from "react-helmet";
import {Spinner} from "../compoenents/common/Spinner";
import {IconReqCard} from "../compoenents/Icons/ReqCard";
import {IconRefresh} from "../compoenents/Icons/Refresh";
import {IconEdit} from "../compoenents/Icons/Edit";

export const LOGIN_FORM_REQUIRED_VALUES = ["username"];

export default function LoginPage() {
    const [input, setInput] = useState({});
    const [validationErr, setValidationErr] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sendCodeLoading, setSendCodeLoading] = useState(false);
    const {login} = useAuth();
    const formRef = useRef(null);
    const validatedAll = useRef(true);
    const phoneRef = useRef(null);
    const passRef = useRef(null);

    useEffect(() => {
        if (!showPassword) phoneRef.current.focus();
        else passRef.current.focus();
    }, [showPassword]);

    const formOnChangeHandler = ({target: {value, name}}) => {
        setValidationErr({});
        validatedAll.current = true;
        const _input = {...input, [name]: value};
        setInput(_input);
    };

    useEffect(() => {
        if (input?.password?.length === 4)
            loginHandler().then((e) => console.log(e));
    }, [input?.password]);

    const handleLogin = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            await loginHandler();
        }
    };

    const loginHandler = async (e) => {
        if (validateForm())
            if (input?.username && !showPassword) {
                // Replace with actual authentication logic
                setLoading(true);
                const result = await sendCode(input?.username);
                if (result?.status) {
                    setShowPassword(true);
                } else
                    alert(result?.errors);
                setLoading(false);
            } else if (showPassword && input?.password) {
                setLoading(true);
                const result = await authorize(input?.username, input?.password);
                if (result?.status) {
                    await login(result?.data);
                    setLoading(false);
                } else {
                    alert("Invalid username or password");
                    setLoading(false);
                }
            } else {
                alert("Invalid username or password");
                setLoading(false);
            }
    };
    const validateForm = () => {
        if (validatedAll.current) {
            let validation = {};
            let isValid = true;
            LOGIN_FORM_REQUIRED_VALUES.forEach((field) => {
                if (!input[field] || input[field] === "") {
                    validation[field] = "لطفا شماره موبایل را وارد کنید.";
                    isValid = false;
                }
                if (field === "username" && input[field] && input[field].length !== 11) {
                    validation[field] = "شماره موبایل باید 11 رقم باشد";
                    isValid = false;
                }
            });
            setValidationErr(validation);
            return isValid;
        }
    };
    const resendCodeHandler = async (e) => {
        setSendCodeLoading(true);
        const result = await sendCode(input?.username);
        if (result?.status) {
            setShowPassword(true);
        }
        setSendCodeLoading(false);
    };
    const changeNumber = async () => {
        setShowPassword(false);
        setInput({});
        phoneRef.current.value = "";
    };
    return (
        <div
            className="flex w-[90%] min-h-[300px] rounded mx-auto mt-[10%] bg-gradient-to-b from-[#6269c6] to-[#081833] shadow-xl p-5">
            <Helmet>
                <title>ورود کاربران</title>
            </Helmet>
            <form
                ref={formRef}
                onSubmit={handleLogin}
                className="w-full flex flex-col gap-y-4"
                autoComplete="off"
            >
                <div className="flex flex-col justify-center items-center gap-y-2 mb-8">
                    <LogoBase/>
                    <div className="mt-2 text-2xl">ورود کاربران</div>
                </div>
                <div
                    className={`flex flex-col gap-y-3 mb-8 ${showPassword ? "hidden" : ""}`}
                >
                    <label htmlFor="username" className="block">
                        نام کاربری :
                    </label>
                    <input
                        ref={phoneRef}
                        name="username"
                        type="tel"
                        autoComplete="off"
                        maxLength="11"
                        className="input-form text-center tracking-[10px]"
                        defaultValue={input.username}
                        onChange={formOnChangeHandler}
                    />
                    {validationErr.username ? <div className="text-orange-700">{validationErr.username}</div> : ""}
                </div>
                <div
                    className={`flex flex-col gap-y-3 ${!showPassword ? "hidden" : ""}`}
                >
                    <label htmlFor="password" className="block">
                        کد :
                    </label>
                    <input
                        ref={passRef}
                        name="password"
                        type="tel"
                        maxLength="4"
                        className="input-form tracking-[10px] text-center"
                        onChange={formOnChangeHandler}
                    />
                    {validationErr.password ? <div>{validationErr.password}</div> : ""}
                </div>

                {showPassword ? (
                    <div className="mt-14 flex justify-between items-center px-2 text-[14px]">
                        <button
                            type="button"
                            disabled={sendCodeLoading}
                            className={`${sendCodeLoading ? "text-zinc-400" : ""} flex gap-x-1 cursor-pointer justify-center items-center`}
                            onClick={() => resendCodeHandler()}
                        >
                            {sendCodeLoading ? (
                                <Spinner width="w-[16px]" height="h-[16px]" border={2}/>
                            ) : (
                                <IconRefresh height={16}/>
                            )}
                            <span>ارسال مجدد کد</span>
                        </button>
                        <div className="flex gap-x-1 cursor-pointer justify-center items-center"
                             onClick={changeNumber}>
                            <IconEdit height={16}/>
                            <span>تغییر شماره</span>
                        </div>
                    </div>
                ) : (
                    ""
                )}
                <button
                    type="submit"
                    className="px-4 flex justify-center py-3 gap-x-3 rounded bg-[#31439d]"
                >
                    {loading ? (
                        <Spinner width="w-[32px]" height="h-[32px]"/>
                    ) : (
                        <>
                            <IconUserBold color="white"/>
                            <span>{showPassword ? "ورود" : "ارسال رمز"}</span>
                        </>
                    )}
                </button>
            </form>
        </div>
    );
}
