import Helmet from "react-helmet";
import {useAuth} from "../hooks/useAuth";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {getUserData} from "../services/login";
import {convertEnToPe, convertToPrice, ToShamsiEnToPe} from "../utiles/Ops";
import {Spinner} from "../compoenents/common/Spinner";

export default function Introductions() {
    const {setUser, token} = useAuth();
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getUserData().then((_userData) => {
            if (_userData?.status) {
                setUserData(_userData?.data);
                setUser(_userData?.data);
                setLoading(false);
            }
        });
    }, [token]);
    return (
        <>
            <Helmet>
                <title>رویدادهای ماندگار</title>
            </Helmet>
            {loading ? (
                <Spinner full/>
            ) : (
                <div className="rounded bg-[#221f3f] p-2">
                    <h2 className="p-2 flex justify-center text-3xl mb-8">رویدادهای ماندگار</h2>
                    <div className="mt-5">
                        {userData?.introductions?.length && userData?.introductions?.map((item, key) => (
                            <div
                                key={key}>
                                <div className="flex flex-col justify-between items-center py-3 gap-y-2 px-2 mb-2">
                                    <div className="rounded w-[100px] py-1"><img src={item.imageUrl}/></div>
                                    <div className="py-1">{item.name}</div>
                                    <div dangerouslySetInnerHTML={{ __html: item.body }} className="py-1"/>
                                </div>
                                <hr width="100%" className="pt-3"/>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}
