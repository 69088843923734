export const fetchData = async (
    query,
    method,
    isJsonRequest = true,
    isBlob = false,
) => {
    try {
        if (query?.url) {
            const headers = new Headers();

            const token = window.localStorage.getItem("token");
            if (isJsonRequest) headers.append("Content-Type", "application/json-patch+json");
            else headers.append("Content-Type", "application/x-www-form-urlencoded");
            if (token) headers.append("Authorization", `Bearer ${token}`);

            const requestOptions = {
                method,
                headers,
                // body: query?.variables ? JSON.stringify(query?.variables) : "",
            };
            if (query?.variables && isJsonRequest) requestOptions.body = JSON.stringify(query?.variables);
            else if (query?.variables && !isJsonRequest) requestOptions.body = query?.variables;
            const response = await fetch(query?.url, requestOptions);

            try {
                // if (!response.ok) return response.status;

                if (isBlob) return response;

                return await response.json();
            } catch (error) {
                if (response.status === 401) return 401;
                else console.log(error);
            }
        }
    } catch (e) {
        console.info(e, "fetchData");
    }
};
