import Helmet from "react-helmet";
import {
    useEffect, useRef,
    useState,
} from "react";
import {Spinner} from "../compoenents/common/Spinner";
import DatePicker from "react-multi-date-picker";
import {useAuth} from "../hooks/useAuth";
import {IconEdit} from "../compoenents/Icons/User";
import {getUserData, saveProfile} from "../services/login";
import persian from "react-date-object/calendars/persian"
import persian_fa from "react-date-object/locales/persian_fa"

const shamsi = require('shamsi-date-converter');
const PROFILE_FORM_REQUIRED_VALUES = [
    "name",
    "lastName",
    // "partyAddress",
    // "birthDate",
    // "marriageDate",
];
export default function ProfilePage() {
    const [calendarValueBirthDate, setCalendarValueBirthDate] = useState()
    const [calendarValueMarriageDate, setCalendarValueMarriageDate] = useState()
    const {setUser, token} = useAuth();
    const [input, setInput] = useState({});
    const [validationErr, setValidationErr] = useState({});
    const [isPending, setIsPending] = useState(false);
    const validatedAll = useRef(true);
    useEffect(() => {
        getUserData().then((_userData) => {
            if (_userData?.status) {
                setUser(_userData?.data);
                setIsPending(false);
                setInput(_userData?.data?.party);
                if (_userData?.data?.party?.birthDate)
                    setCalendarValueBirthDate(shamsi.gregorianToJalali(_userData?.data?.party?.birthDate).join('/'))
                if (_userData?.data?.party?.marriageDate)
                    setCalendarValueMarriageDate(shamsi.gregorianToJalali(_userData?.data?.party?.marriageDate).join('/'))
                setValidationErr({});
                validatedAll.current = false;
            }
        });
    }, [token]);

    if (isPending) return <Spinner full/>;
    const formOnChangeHandler = ({target: {value, name}}) => {
        setValidationErr({});
        validatedAll.current = true;
        setInput((prevInput) => ({...prevInput, [name]: value})); // استفاده از تابع قبلی
    };

    const validateForm = () => {
        if (validatedAll.current) {
            let validation = {};
            let isValid = true;
            PROFILE_FORM_REQUIRED_VALUES.forEach((field) => {
                if (!input[field] || input[field] === "") {
                    validation[field] = "این فیلد اجباری است.";
                    isValid = false;
                }
            });
            setValidationErr(validation);
            return isValid;
        } else
            return true;
    };

    const submitForm = async () => {
        if (validateForm()) {
            setIsPending(true); // در اینجا وضعیت در حال انتظار را تنظیم کنید
            try {
                const data = await saveProfile(input);
                if (data.status) {
                    alert("اطلاعات با موفقیت ثبت شد.");
                } else if (data.errors) {
                    alert(data.errors);
                } else
                    alert("خطا در ذخیره اطلاعات.");
            } catch (error) {
                console.error("Error saving profile:", error);
                alert("خطا در ذخیره اطلاعات.");
            } finally {
                setIsPending(false); // وضعیت در حال انتظار را برگردانید
            }
        }
    };

    return (
        <div>
            <Helmet>
                <title>صفحه مشخصات کاربر</title>
            </Helmet>
            <div className="p-5">
                <h2 className="p-2 flex justify-center text-3xl mb-8">مشخصات کاربر</h2>
                <div className="flex flex-col gap-y-3 mb-4">
                    <label htmlFor="name" className="block">
                        نام :
                    </label>
                    <input
                        defaultValue={input?.name}
                        name="name"
                        type="text"
                        autoComplete="off"
                        className="input-form"
                        onChange={formOnChangeHandler}
                    />
                    {validationErr.name ? (
                        <div className="text-red-500">{validationErr.name}</div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="flex flex-col gap-y-3 mb-4">
                    <label htmlFor="lastName" className="block">
                        نام خانوادگی :
                    </label>
                    <input
                        defaultValue={input?.lastName}
                        name="lastName"
                        type="text"
                        autoComplete="off"
                        className="input-form"
                        onChange={formOnChangeHandler}
                    />
                    {validationErr.lastName ? (
                        <div className="text-red-500">{validationErr.lastName}</div>
                    ) : (
                        ""
                    )}
                </div>
                {/*<div className="flex flex-col gap-y-3 mb-4">*/}
                {/*    <label htmlFor="partyAddress" className="block">*/}
                {/*        آدرس :*/}
                {/*    </label>*/}
                {/*    <input*/}
                {/*        defaultValue={userData?.party?.partyAddress}*/}
                {/*        name="partyAddress"*/}
                {/*        type="text"*/}
                {/*        autoComplete="off"*/}
                {/*        className="input-form"*/}
                {/*        onChange={formOnChangeHandler}*/}
                {/*    />*/}
                {/*    {validationErr.partyAddress ? (*/}
                {/*        <div className="text-red-500">{validationErr.partyAddress}</div>*/}
                {/*    ) : (*/}
                {/*        ""*/}
                {/*    )}*/}
                {/*</div>*/}
                <div className="flex flex-col gap-y-3 mb-2">
                    <label htmlFor="birthDate" className="block">
                        تاریخ تولد :
                    </label>
                    <DatePicker
                        inputClass="input-form"
                        format="YYYY/MM/DD"
                        value={calendarValueBirthDate}
                        calendar={persian}
                        locale={persian_fa}
                        onChange={e => {
                            if (e) {
                                setCalendarValueBirthDate(e.toUTC().toDate().toISOString())
                                formOnChangeHandler({
                                    target: {
                                        value: e.toUTC().toDate().toISOString(),
                                        name: "birthDate"
                                    }
                                });
                            } else {
                                setCalendarValueBirthDate(null)
                                formOnChangeHandler({
                                    target: {
                                        value: null,
                                        name: "birthDate"
                                    }
                                });
                            }
                        }}
                    />

                    {validationErr?.birthDate ? (
                        <div className="text-red-500">{validationErr?.birthDate}</div>
                    ) : (
                        ""
                    )}
                </div>
                <div className="flex flex-col gap-y-3 mb-2">
                    <label htmlFor="marriageDate" className="block">
                        تاریخ ازدواج :
                    </label>
                    <DatePicker
                        inputClass="input-form"
                        format="YYYY/MM/DD"
                        value={calendarValueMarriageDate}
                        calendar={persian}
                        locale={persian_fa}
                        onChange={e => {
                            if (e) {
                                setCalendarValueMarriageDate(e.toUTC().toDate().toISOString())
                                formOnChangeHandler({
                                    target: {
                                        value: e.toUTC().toDate().toISOString(),
                                        name: "marriageDate"
                                    }
                                });
                            } else {
                                setCalendarValueMarriageDate(null)
                                formOnChangeHandler({
                                    target: {
                                        value: null,
                                        name: "marriageDate"
                                    }
                                });
                            }
                        }}
                    />
                    {validationErr?.marriageDate ? (
                        <div className="text-red-500">{validationErr?.marriageDate}</div>
                    ) : (
                        ""
                    )}
                </div>
                <button
                    type="button"
                    onClick={submitForm}
                    className="px-4 flex justify-center py-3 gap-x-3 rounded bg-[#31439d] mt-14 w-full"
                >
                    <IconEdit color="white"/>
                    <span>ویرایش</span>
                </button>
            </div>
        </div>
    );
}
